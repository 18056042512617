@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer components {
  *,
  ::before,
  ::after {
    @apply box-border;
  }

  html,
  body {
    @apply m-0 p-0;
  }

  body {
    background-size: 500px !important;
    color: #555;
    font-size: 17px;
    line-height: 1.6;
    @apply bg-white font-raleway font-normal overflow-x-hidden m-0 p-0 relative w-full;
  }

  h1 {
    font-family: 'Quicksand', sans-serif;
    letter-spacing: -1px;
    margin: 6px 0 12px;
    @apply md:text-50px leading-normal md:leading-75px text-white font-light text-center;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold;
  }

  h3,
  h4,
  h5,
  h6 {
    color: #2a6c89;
  }

  a,
  button {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  a.website-btn,
  a.btn {
    border-radius: 40px;
    margin-top: -4px;
    margin-bottom: 20px;
    padding: 6px 12px 7px 40px;
    @apply bg-globe-icon bg-app-strip bg-left bg-no-repeat text-white inline-block font-semibold no-underline;
  }

  a.btn {
    background: #2a6c89;
    padding: 12px 20px;
    @apply mt-0 text-center;
  }

  a.website-btn:hover,
  a.website-btn:focus,
  a.website-btn:active {
    @apply bg-tooltip;
  }

  a.btn:hover,
  a.btn:focus,
  a.btn:active {
    background: #47baad;
  }

  .intro-para {
    font-size: 1.1em;
  }

  .site-header {
    padding: 30px 24px 0 24px;
    @apply text-center;
    background-color: #000;
  }

  .site-header img {
    max-height: 80px;
  }

  .hero {
    min-height: 40vh;
    padding: 24px 48px;
    @apply bg-hero-image bg-app-strip bg-no-repeat bg-center bg-cover text-white flex flex-wrap items-center justify-center text-center w-full;
  }

  .hero .wrapper {
    @apply relative;
  }

  .wrapper {
    max-width: 1100px;
    @apply my-0 mx-auto;
  }

  .main {
    padding: 32px 24px 48px;
    background-color: #000;
  }

  .rainbow-band {
    height: 15px;
    @apply bg-polygon-pattern bg-no-repeat bg-center bg-cover;
  }

  .widget {
    height: 280px;
  }

  .logo {
    margin-bottom: 24px;
    @apply w-full sm:w-auto;
  }

  .site-header .logo {
    height: 40px;
    @apply w-auto;
  }

  .top-bottom.logo {
    max-width: 360px;
    @apply h-auto w-full;
  }

  .top-bottom {
    @apply flex flex-col justify-between items-center;
  }

  .page-footer {
    background-color: #262626;
    color: #fefefe;
    font-size: 0.9em;
    @apply w-full;
  }

  .page-footer a,
  .page-footer h3 {
    @apply text-white;
  }

  .footer-social a {
    margin: 0 12px 10px 0;
    @apply inline-block no-underline;
  }

  .page-footer .wrapper {
    max-width: 1088px;
    padding: 48px 24px;
    @apply my-0 mx-auto text-center;
  }

  .video {
    background: #eaeaea;
  }
  
  .video-wrapper {
    max-width: 680px;
    padding: 40px 24px 60px;
    @apply my-0 mx-auto text-center;
  }

  @media (min-width: 600px) {
    .widgets-grid {
      @apply flex flex-wrap justify-between;
    }
  }

  @media (min-width: 700px) {
    .two-col {
      @apply flex flex-wrap justify-between text-left;
    }
    .two-col > * {
      width: 48%;
    }
    .right {
      @apply text-right;
    }
  }

  .tooltip {
    width: 200px !important;
    @apply invisible absolute;
  }

  @media screen and (min-width: 768px) {
    .tooltip {
      right: 10px;
    }
  }

  .has-tooltip:hover .tooltip {
    z-index: 100;
    @apply visible;
  }

  .widget {
    height: 280px;
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loader {
  border-style: solid;
  border-top-color: #2a6c89;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
